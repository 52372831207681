// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ambasadorius-js": () => import("./../src/pages/ambasadorius.js" /* webpackChunkName: "component---src-pages-ambasadorius-js" */),
  "component---src-pages-egzaminas-begin-js": () => import("./../src/pages/egzaminas/begin.js" /* webpackChunkName: "component---src-pages-egzaminas-begin-js" */),
  "component---src-pages-egzaminas-diploma-js": () => import("./../src/pages/egzaminas/diploma.js" /* webpackChunkName: "component---src-pages-egzaminas-diploma-js" */),
  "component---src-pages-egzaminas-isbandyk-js": () => import("./../src/pages/egzaminas/isbandyk.js" /* webpackChunkName: "component---src-pages-egzaminas-isbandyk-js" */),
  "component---src-pages-egzaminas-registration-js": () => import("./../src/pages/egzaminas/registration.js" /* webpackChunkName: "component---src-pages-egzaminas-registration-js" */),
  "component---src-pages-egzaminas-test-eg-js": () => import("./../src/pages/egzaminas/test-eg.js" /* webpackChunkName: "component---src-pages-egzaminas-test-eg-js" */),
  "component---src-pages-egzaminas-test-js": () => import("./../src/pages/egzaminas/test.js" /* webpackChunkName: "component---src-pages-egzaminas-test-js" */),
  "component---src-pages-egzaminas-try-test-js": () => import("./../src/pages/egzaminas/try-test.js" /* webpackChunkName: "component---src-pages-egzaminas-try-test-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privatumas-js": () => import("./../src/pages/privatumas.js" /* webpackChunkName: "component---src-pages-privatumas-js" */),
  "component---src-pages-taisykles-js": () => import("./../src/pages/taisykles.js" /* webpackChunkName: "component---src-pages-taisykles-js" */)
}

